<template>
  <div class="content">
    <div class="btc-info-picker-box">
      <div class="item hand" @click="handleClickYear">
        <div class="label">
          <span v-if="languageIndex === 1">{{ selectYear || 'Year' }}</span>
          <span v-if="languageIndex === 2">{{ selectYear || 'Año' }}</span>
          <span v-if="languageIndex === 3">{{ selectYear || 'Ano' }}</span>
          <div :class="{ 'arrow-up': !showYear, 'arrow-down': showYear }" class="btc-arrow ac-1" style="margin-left: 10px;"/>
        </div>
        <div v-show="showYear" class="pull-down">
          <div v-for="(item, index) in year" :key="item" :class="{ active: yearIndex === index }" @click.stop="handleClickYearItem(item, index)">{{ item }}</div>
        </div>
      </div>
      <div class="item hand" @click="handleClickMonth">
        <div class="label">
          <span v-if="languageIndex === 1">{{ selectMonth || 'Month' }}</span>
          <span v-if="languageIndex === 2">{{ selectMonth || 'Mes' }}</span>
          <span v-if="languageIndex === 3">{{ selectMonth || 'Mês' }}</span>
          <div :class="{ 'arrow-up': !showMonth, 'arrow-down': showMonth }" class="btc-arrow ac-1" style="margin-left: 10px;"/>
        </div>
        <div v-show="showMonth" class="pull-down">
          <div v-for="(item, index) in month" :key="item.value" :class="{ active: monthIndex === index }" @click.stop="handleClickMonthItem(item, index)">{{ item.label }}</div>
        </div>
      </div>
    </div>
<!--    <div class="picker-country">
      <div class="picker-country-img">
        <img v-if="languageIndex === 1" src="@/assets/image/btcinfo/report/EN_True.png" style="width: 48px; height: 22px;">
        <img v-if="languageIndex != 1" src="@/assets/image/btcinfo/report/EN_False.png" style="width: 26px; height: 17px;" @click="onLanguageChange(1)">
      </div>
      <div class="picker-country-img">
        <img v-if="languageIndex === 2" src="@/assets/image/btcinfo/report/Xiyu_True.png" style="width: 48px; height: 22px;">
        <img v-if="languageIndex != 2" src="@/assets/image/btcinfo/report/Xiyu_False.png" style="width: 26px; height: 17px;" @click="onLanguageChange(2)">
      </div>
      <div class="picker-country-img">
        <img v-if="languageIndex === 3" src="@/assets/image/btcinfo/report/Puyu_True.png" style="width: 48px; height: 22px;">
        <img v-if="languageIndex != 3" src="@/assets/image/btcinfo/report/Puyu_False.png" style="width: 26px; height: 17px;" @click="onLanguageChange(3)">
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'BTCInfoReportDatePicker',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showYear: false,
      showMonth: false,
      yearIndex: -1,
      monthIndex: -1,
      month: [],
      selectYear: '2024',
      selectMonth: '',
      monthArray: [
        { label: 'January', value: '1' },
        { label: 'February', value: '2' },
        { label: 'March', value: '3' },
        { label: 'April', value: '4' },
        { label: 'May', value: '5' },
        { label: 'June', value: '6' },
        { label: 'July', value: '7' },
        { label: 'August', value: '8' },
        { label: 'September', value: '9' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
      ],
      monthArray2: [
        { label: 'Enero', value: '1' },
        { label: 'Febrero', value: '2' },
        { label: 'Marzo', value: '3' },
        { label: 'Abril', value: '4' },
        { label: 'Mayo', value: '5' },
        { label: 'Junio', value: '6' },
        { label: 'Julio', value: '7' },
        { label: 'Agosto', value: '8' },
        { label: 'Septiembre', value: '9' },
        { label: 'Octubre', value: '10' },
        { label: 'Noviembre', value: '11' },
        { label: 'Diciembre', value: '12' }
      ],
      monthArray3: [
        { label: 'Janeiro', value: '1' },
        { label: 'Fevreiro', value: '2' },
        { label: 'Março', value: '3' },
        { label: 'Abril', value: '4' },
        { label: 'Maio', value: '5' },
        { label: 'Junho', value: '6' },
        { label: 'Julho', value: '7' },
        { label: 'Agosto', value: '8' },
        { label: 'Setembro', value: '9' },
        { label: 'Outubro', value: '10' },
        { label: 'Novembro', value: '11' },
        { label: 'Dezembro', value: '12' }
      ],
      languageIndex: 1
    }
  },
  watch: {
    data (oval, nval) {
      if (oval !== nval) {
        this.getMonth(this.selectYear)
      }
    }
  },
  computed: {
    year () {
      return this.data.years
    }
  },
  mounted () {
    this.$nextTick(function () {
      setTimeout(() => {
        this.getMonth(this.selectYear)
      }, 1000)
    })
  },
  methods: {
    // onLanguageChange (index) {
    //   this.languageIndex = index
    //   this.$emit('change-language', this.languageIndex)
    // },
    getMonth (year) {
      const monthArr = this.data[year]
      if (this.languageIndex === 1) {
        this.month = this.monthArray.filter(v => {
          return monthArr.includes(v.value)
        })
        if (this.month.length === 0) {
          this.month = [
            { label: 'January', value: '1' },
            { label: 'February', value: '2' },
            { label: 'March', value: '3' },
            { label: 'April', value: '4' },
            { label: 'May', value: '5' },
            { label: 'June', value: '6' },
            { label: 'July', value: '7' },
            { label: 'August', value: '8' },
            { label: 'September', value: '9' },
            { label: 'October', value: '10' },
            { label: 'November', value: '11' },
            { label: 'December', value: '12' }
          ]
        }
      }
      if (this.languageIndex === 2) {
        this.month = this.monthArray2.filter(v => {
          return monthArr.includes(v.value)
        })
        if (this.month.length === 0) {
          this.month = [
            { label: 'Enero', value: '1' },
            { label: 'Febrero', value: '2' },
            { label: 'Marzo', value: '3' },
            { label: 'Abril', value: '4' },
            { label: 'Mayo', value: '5' },
            { label: 'Junio', value: '6' },
            { label: 'Julio', value: '7' },
            { label: 'Agosto', value: '8' },
            { label: 'Septiembre', value: '9' },
            { label: 'Octubre', value: '10' },
            { label: 'Noviembre', value: '11' },
            { label: 'Diciembre', value: '12' }
          ]
        }
      }
      if (this.languageIndex === 3) {
        this.month = this.monthArray3.filter(v => {
          return monthArr.includes(v.value)
        })
        if (this.month.length === 0) {
          this.month = [
            { label: 'Janeiro', value: '1' },
            { label: 'Fevreiro', value: '2' },
            { label: 'Março', value: '3' },
            { label: 'Abril', value: '4' },
            { label: 'Maio', value: '5' },
            { label: 'Junho', value: '6' },
            { label: 'Julho', value: '7' },
            { label: 'Agosto', value: '8' },
            { label: 'Setembro', value: '9' },
            { label: 'Outubro', value: '10' },
            { label: 'Novembro', value: '11' },
            { label: 'Dezembro', value: '12' }
          ]
        }
      }
    },
    handleClickYear () {
      this.showYear = !this.showYear
    },
    handleClickMonth () {
      this.showMonth = !this.showMonth
    },
    handleClickYearItem (item, index) {
      this.getMonth(item)
      this.yearIndex = index
      this.selectYear = item
      this.$emit('change-year', item)
      this.showYear = false
    },
    handleClickMonthItem (item, index) {
      this.monthIndex = index
      this.selectMonth = item.label
      this.$emit('change-month', item.value)
      this.showMonth = false
    }
  }
}
</script>

<style lang="scss" scoped>

.btc-info-picker-box {
  height: 30px;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid #d1d1d1;
  padding: 3px;
  background-color: #ffffff;
  font-size: 12px;
}

.btc-info-picker-box > .item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  position: relative;
  padding: 0 10px;
}

.btc-info-picker-box > .item > .label {
  display: flex;
  align-items: center;
}

.btc-info-picker-box > .item:first-child {
  border-radius: 15px 0 0 15px;
}

.btc-info-picker-box > .item:last-child {
  margin-left: 5px;
  border-radius: 0 15px 15px 0;
}

.btc-info-picker-box > .item > .pull-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 23px;
  background-color: burlywood;
  z-index: 1;
}

.btc-info-picker-box > .item > .pull-down > div {
  width: 72px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
.active {
  background-color: #cce0f1!important;
}

.picker-country{
  width: 160px;
  height: 30px;
  background: rgba(230,230,230,0.39);
  opacity: 1;
  border-radius: 6px;
  margin-left: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0 2px;
}

.picker-country-img{
  width: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  line-height: 30px;
  cursor: pointer;
}

.content{
  display: flex;
  justify-content: flex-start;
}
</style>
